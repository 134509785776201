import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';

const EnterpriseHeader = () => {
  const config = process.env;
  const REACT_APP_PROVISIONING_HOST = config.REACT_APP_PROVISIONING_HOST;
  const css1 = `${REACT_APP_PROVISIONING_HOST}/provisioning/assets/styles/base.css`;
  const css2 = `${REACT_APP_PROVISIONING_HOST}/provisioning/assets/styles/enterprisenav.css`;
  const css3 = `${REACT_APP_PROVISIONING_HOST}/provisioning/shared/branding.seam`;
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'enterpriseHeaderScript';
    script.setAttribute('data-testid', 'enterpriseHeaderScript');
    script.src = `${REACT_APP_PROVISIONING_HOST}/provisioning/shared/entnav.seam`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [REACT_APP_PROVISIONING_HOST]);

  return (
    <div id="enterpriseHeaderContainer">
      <div id="enterpriseHeader">
        <Helmet>
          <link rel="stylesheet" href={css1} type="text/css"/>
          <link rel="stylesheet" href={css2} type="text/css"/>
          <link rel="stylesheet" href={css3} type="text/css"/>
        </Helmet>
        <div className="welcomeBar"/>
        <div className="appsLinks">
          <ul>
            <li><a href="https://app.boatwizard.com/">Home</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseHeader;
