import {createSlice} from '@reduxjs/toolkit';
import getQueryParameter from '../../utils/query-parameters';
import { ORDER_BY_MAPPING } from '../../utils/constants';

const orderByInitialState = getQueryParameter('order_by', '');

const orderBySlice = createSlice({
  name: 'orderBy',
  initialState: orderByInitialState,
  reducers: {
    setOrderBy: (state, action) => {
      return ORDER_BY_MAPPING[action.payload] ?? orderByInitialState;
    }
  }
});

export const {setOrderBy} = orderBySlice.actions;

export const selectOrderBy = (state) => state.orderBy;

export default orderBySlice.reducer;
