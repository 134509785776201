import { useMemo } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { useAuth } from './useAuth';
import { API_LOGIN } from '../utils/constants';

const { REACT_APP_PUBLIC_API_ENDPOINT } = process.env;

export const createUseHttpClient = ({ useAuth, axios, REACT_APP_PUBLIC_API_ENDPOINT }) => () => {
  const { runAuthProcess, getToken } = useAuth();

  const httpClient = useMemo(() => {
    const http = axios.create({
      baseURL: `${REACT_APP_PUBLIC_API_ENDPOINT}/yw-fsbo`
    });

    http.interceptors.request.use((config) => {
      const token = getToken();
      if (token && !includes(config.url, API_LOGIN)) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    http.interceptors.response.use(null, async (error) => {
      if (get(error, 'response.status') === 401 && !includes(get(error, 'config.url'), API_LOGIN)) {
        await runAuthProcess();
        return http.request(error.config);
      }
      throw error;
    });

    return http;

    // reason: runAuthProcess and getToken are passed by reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    httpClient
  };
};

export const useHttpClient = createUseHttpClient({ useAuth, axios, REACT_APP_PUBLIC_API_ENDPOINT });
