import React, { useEffect, useState } from 'react';
import getQueryParameter from './utils/query-parameters';
import Branding from './components/Branding';
import Filter from './components/Filter';
import List from './components/List';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './store/user/userSlice';
import './App.scss';
import { selectLeads, setLeads } from './store/leads/leadsSlice';
import { useFetch } from './hooks/useFetch';
import { ProtectedApp } from './ProtectedApp.js';

export function App() {
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState(() => getQueryParameter('selectedCurrency'));
  const [orderBy, setOrderBy] = useState(() => getQueryParameter('order_by', ''));
  const leads = useSelector(selectLeads);

  useEffect(() => {
    const queryParams = [
      { name: 'order_by', value: orderBy },
      { name: 'selectedCurrency', value: selectedCurrency }
    ].map((param) => {
      if (!param.value) {
        return param.value;
      }
      return `${param.name}=${param.value}`;
    }).filter((param) => !!param);
    window.history.pushState('', '', `${queryParams.length ? '?' : ''}${queryParams.join('&')}`);
  }, [orderBy, selectedCurrency]);

  const user = useSelector(selectUser);
  const showDelete = user?.isCsr;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/logout.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useFetch({
    path: '/leads',
    params: { orderby: orderBy, selectedCurrency },
    handleSaveData: (data) => {
      dispatch(setLeads({...data}));
    }
  });

  return (
    <div id='fsbodiv'>
      <Branding />
      <p>YachtWorld.com only accepts boats for sale advertised by brokers and dealers.
        However, since we get so many advertising inquiries from owner/sellers,
        we <a href='https://www.yachtworld.com/boats-common/fsbo_ad.html'>educate</a> them
        about broker services and invite them to attract a broker by "posting" their boat
        for sale for viewing by our members (the public can not view this information). This
        is a great way to pick up new listings. Every month about 150-300 new owner/sellers
        take us up on our offer, and their information is displayed here for up to 30 days.
        This information is currently provided as a free service to our members.</p>
      <Filter
        orderBy={orderBy}
        handleOrderByChange={newOrderBy => setOrderBy(newOrderBy)}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency} />
      <hr />
      <>
        <List showDelete={showDelete} selectedCurrency={selectedCurrency} type='power' results={leads?.power ?? []} />
        <List showDelete={showDelete} selectedCurrency={selectedCurrency} type='sail' results={leads?.sail ?? []} />
      </>
    </div>
  );
}

const AppWithProtection = () => <ProtectedApp><App/></ProtectedApp>;
export default AppWithProtection;
