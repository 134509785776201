import React from 'react';

import './styles.scss';

export default function Branding() {
  return (
    <h1 id="toolbrand">
      <span className="logo"></span>
      <span className="tool">FSBO Leads</span>
    </h1>
  );
}
