import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import leadsReducer from './leads/leadsSlice';
import orderByReducer from './orderBy/orderBySlice';

export const localStorageMiddleware = store => next => action => {
  next(action);

  const now = Date.now();
  const expirationTime = now + 24 * 60 * 60 * 1000;
  localStorage.setItem('reduxState', JSON.stringify({data: store.getState(), expirationTime: expirationTime}));
};

export const getPreloadedState = () => {
  const storedState = localStorage.getItem('reduxState');
  if (storedState && JSON.parse(storedState).expirationTime > Date.now()) {
    return JSON.parse(storedState).data;
  }

  return {};
};

const initStore = preloadedState => {
  return configureStore({
    reducer: {
      user: userReducer,
      leads: leadsReducer,
      orderBy: orderByReducer
    },
    middleware: [localStorageMiddleware],
    preloadedState
  });
};

export const store = initStore(getPreloadedState());
