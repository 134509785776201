import { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { login } from '../store/user/userSlice';
import { formatWithoutTicket } from '../utils/urlHelpers';
const { REACT_APP_PUBLIC_API_ENDPOINT, REACT_APP_CAS_LOGIN } = process.env;

const internals = {};

const getUrlParams = () => {
  return new window.URLSearchParams(window.location.search);
};

const getService = () => {
  return formatWithoutTicket(window.location);
};

const redirectToCas = service => {
  window.location.href = `${REACT_APP_CAS_LOGIN}?service=${service}`;
};

export const removeTicketFromUrlFactory = ({ window, formatWithoutTicket }) => () => {
  window.history.replaceState(null, '', formatWithoutTicket(window.location));
};
const removeTicketFromUrl = removeTicketFromUrlFactory({ window, formatWithoutTicket });

export const callLoginFactory = ({ axios }) => async ({ ticket, service }) => {
  const apiLogin = `${REACT_APP_PUBLIC_API_ENDPOINT}/yw-fsbo/login`;
  return axios.get(apiLogin, { params: { ticket, service } });
};
const callLogin = callLoginFactory({ axios });

export const createUseAuthHook = ({
  getUrlParams,
  getService,
  redirectToCas,
  useDispatch,
  callLogin,
  removeTicketFromUrl,
  internals
}) => () => {
  // a state flag is needed to trigger the re-render
  // since the token is saved in a global variable
  const [tokenAvailable, setTokenAvailable] = useState(false);

  const dispatch = useDispatch();
  const handleSaveUser = (user) => {
    dispatch(login(user));
  };

  const getToken = () => {
    return internals?.token ?? null;
  };

  const runAuthProcess = async () => {
    const urlParams = getUrlParams();
    const ticket = urlParams.get('ticket');

    const service = getService();

    if (!ticket) {
      redirectToCas(service);
    } else {
      const loginResponse = await callLogin({ service, ticket});
      internals.token = loginResponse.data.token;
      setTokenAvailable(true);
      handleSaveUser(loginResponse.data.user);
      removeTicketFromUrl();
    }
  };

  return {
    runAuthProcess,
    getToken,
    tokenAvailable
  };
};

export const useAuth = createUseAuthHook({
  getUrlParams,
  getService,
  redirectToCas,
  useDispatch,
  removeTicketFromUrl,
  callLogin,
  internals
});

