import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHttpClient } from './useHttpClient';

const stringifyParams = (params) => {
  const newParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(subValue => {
        newParams.append(key, subValue);
      });
    } else {
      newParams.append(key, value);
    }
  });
  return newParams.toString();
};

const removeEmptyParams = (params) => Object.entries(params).reduce((_params, [paramKey, paramValue]) => {

  const checkIfIsEmpty = (value) => {
    if (typeof value === 'object') {
      return isEmpty(value);
    }
    return !value;
  };

  if (!checkIfIsEmpty(paramValue)) {
    return {
      ..._params,
      [paramKey]: paramValue
    };
  }
  return _params;
}, {});

export const createUseFetch = ({ useHttpClient }) => ({path, params, shouldRun = true, handleSaveData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { httpClient } = useHttpClient();

  let completedPath = path;
  if (params) {
    const cleanedParams = removeEmptyParams(params);
    const stringifiedParams = stringifyParams(cleanedParams);
    completedPath = `${completedPath}?${stringifiedParams}`;
  }

  useEffect(() => {
    if (!shouldRun) {
      return;
    }

    setLoading(true);
    const fetchData = async () => {
      const { data } = await httpClient.get(completedPath);
      if (handleSaveData) {
        handleSaveData(data);
      } else {
        setData(data);
      }
      setLoading(false);
      setError(null);
    };

    fetchData().catch(error => {
      setLoading(false);
      setError(error);
    });

    // reason: handleSaveData is passed by reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRun, completedPath, httpClient]);

  return {
    data: handleSaveData ? undefined : data,
    loading,
    error
  };
};

export const useFetch = createUseFetch({ useHttpClient });
