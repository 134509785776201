import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import {createFrontloadState, FrontloadProvider} from 'react-frontload';

const frontloadState = createFrontloadState.client({
  serverRenderedData: window.__FRONTLOAD_STATE__
});

const Application = (
  <React.StrictMode>
    <Provider store={store}>
      <FrontloadProvider initialState={frontloadState}>
        <App/>
      </FrontloadProvider>
    </Provider>
  </React.StrictMode>
);

const root = document.querySelector('#root');
/* istanbul ignore if*/
ReactDOM.hydrate(Application, root);
