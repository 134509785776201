import { useEffect } from 'react';
import { useAuth } from './hooks/useAuth';
import EnterpriseHeader from './components/EnterpriseHeader';

export function ProtectedApp({ children }) {
  const { runAuthProcess, tokenAvailable } = useAuth();

  useEffect(() => {
    if (!tokenAvailable) {
      runAuthProcess();
    }
  }, [runAuthProcess, tokenAvailable]);

  return (
    <>
      <EnterpriseHeader />
      { tokenAvailable && children }
    </>
  );
};
