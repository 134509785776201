import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import map from 'lodash/map';
import { CURRENCIES, DEFAULT_CURRENCY, LIST_ANCHORS, ORDER_BUTTONS } from '../../utils/constants';
import './styles.scss';

const OrderByOption = ({ onClick, text, selected }) => (
  <button className={`option ${selected ? 'selected' : ''}`} onClick={onClick}>{text}</button>
);

export default function Filter({ selectedCurrency, setSelectedCurrency, handleOrderByChange, orderBy }) {
  const data = useMemo(() => {
    return filterLoader();
  }, []);

  return (
    <>
      <p>
        {map(data.urls, (value, key) => <span key={key}>[<a href={value}>{key}</a>]</span>)}
        {map(data.buttons,(value, key) => <OrderByOption key={key} onClick={()=>(handleOrderByChange(value))} text={key} selected={orderBy === value} /> )}
      </p>
      <label>
        <span className="left right">Choose a currency to compare prices</span>
        <select name="currency" defaultValue={selectedCurrency || DEFAULT_CURRENCY} onChange={(e) => setSelectedCurrency(e.target.value)}>
          {map(data.currency, (currency) =>
            <option key={currency.code} value={currency.code}>{currency.name}</option>)}
        </select>
      </label>
    </>
  );
}

Filter.propTypes = {
  currency: PropTypes.object,
  selectedCurrency: PropTypes.string,
  setSelectedCurrency: PropTypes.func,
  handleOrderByChange: PropTypes.func
};

export function filterLoader() {
  return { currency: CURRENCIES.reverse(), urls: LIST_ANCHORS, buttons: ORDER_BUTTONS };
}
