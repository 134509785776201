import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { lang: 'en' },
  reducers: {
    login: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { login } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
