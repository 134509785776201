import { createSlice } from '@reduxjs/toolkit';
import { LEADS_INITIAL_STATE } from '../../utils/constants';



const leadsSlice = createSlice({
  name: 'leads',
  initialState: LEADS_INITIAL_STATE,
  reducers: {
    setLeads: (state, action) => {
      return action.payload;
    }
  }
});

export const { setLeads } = leadsSlice.actions;

export const selectLeads = (state) => state.leads;

export default leadsSlice.reducer;
