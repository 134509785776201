export const API_LOGIN = '/login';
export const DEFAULT_CURRENCY = 'USD';
export const CURRENCIES = [
  {
    symbol: 'US$',
    name: 'US Dollars',
    code: 'USD'
  },
  {
    symbol: 'SEK',
    name: 'Swedish Kronor',
    code: 'SEK'
  },
  {
    symbol: 'RUR',
    name: 'Russian Rubles',
    code: 'RUB'
  },
  {
    symbol: 'NZ$',
    name: 'New Zealand Dollars',
    code: 'NZD'
  },
  {
    symbol: 'NKr',
    name: 'Norwegion Krone',
    code: 'NOK'
  },
  {
    symbol: '&#163;',
    name: 'Pounds Sterling',
    code: 'GBP'
  },
  {
    symbol: '&euro;',
    name: 'Euros',
    code: 'EUR'
  },
  {
    symbol: 'kr',
    name: 'Danish Kroner',
    code: 'DKK'
  },
  {
    symbol: 'Can$',
    name: 'Canadian Dollars',
    code: 'CAD'
  },
  {
    symbol: 'A$',
    name: 'Australian Dollars',
    code: 'AUD'
  }
];
export const ORDER_BY_MAPPING = {
  'length': '?orderby=length',
  'date': '?orderby=date',
  'price': '?orderby=price'
};
export const LEADS_INITIAL_STATE = {
  power: [],
  sail: []
};
export const LIST_ANCHORS = {
  Power: '#power',
  Sail: '#sail'
};
export const ORDER_BUTTONS = {
  'Order by Length': 'length',
  'Order by Date': 'date',
  'Order by Price': 'price'
};
export const RETRY_CALLS = 2;
