import React, { Fragment } from 'react';

import { PropTypes } from 'prop-types';

import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';

import { getSymbol } from '../../utils/currencyHelpers';

import './styles.scss';

export default function List({selectedCurrency, type, results, showDelete}) {
  const columnSpan = showDelete ? '7' : '6';
  return (
    <>
      <h2 id={type}>{upperFirst(type)} ({results.length})</h2>

      <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th valign="top" align="left" colSpan="2">Boat</th>
            <th valign="top" align="left">Year</th>
            <th valign="top" align="left">Price</th>
            <th valign="top" align="left">Location</th>
            <th valign="top" align="left">Entered</th>
            {showDelete && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {map(results, (result, key) =>
            <Fragment key={key}>
              <tr>
                <td valign="middle" align="left" rowSpan="2">{result.boat.length}'</td>
                <td valign="top" align="left">{result.boat.make} {result.boat.model}</td>
                <td valign="top" align="left">{result.boat.year}</td>
                <td valign="top" align="left">{getSymbol(selectedCurrency ? selectedCurrency : result.boat.originalCurrency)} {result.boat.prices[selectedCurrency || result.boat.originalCurrency]}</td>{/* @TODO This $ should be dynamic in the future. */}
                <td valign="top" align="left">{result.boat.location}</td>
                <td valign="top" align="left">{(new Date(Date.parse(result.listed))).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}</td>
                {showDelete && (<td align="middle" rowSpan="2">
                  <form name="delete_fsbo" action="#">{/* @TODO This # should be an API route in the future. */}
                    <input type="hidden" name="fsbo_id" value={''} />{/* @TODO Where does this ID come from if not the API (was not in dummy data from ticket)? */}
                    <input type="submit" name="delete" value="Delete" />
                  </form>
                </td>)}
              </tr>
              <tr>
                <td valign="top" colSpan={columnSpan}><span>Contact {result.contact.name}</span>, <span>
                  <a href={`tel:+${result.contact.phone}`}>{result.contact.phone}</a></span>, <span>
                  <a href={`mailto:${result.contact.email}`}>{result.contact.email}</a></span></td>
              </tr>
            </Fragment>
          )}
        </tbody>
      </table>
    </>
  );
}

List.propTypes = {
  results: PropTypes.array,
  selectedCurrency: PropTypes.string,
  type: PropTypes.string,
  showDelete: PropTypes.bool
};
